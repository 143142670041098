import {encrypt, getEncryptionKey} from '@famly/stat_ts-utils_cryptography';

import {buildUserContextKey} from './build-user-key';
import {getKeyPart} from './get-key-part';
import {loginId, secret, userContextId} from './local-storage-vars';
import {saveToLocalStorage} from './save-to-local-storage';

/**
 * Encrypts the given payload and saves it to the user context's localstorage.
 *
 * @param key key to use in localstorage
 * @param payload payload to save in localstorage at [key]
 * @param withKeyPart boolean telling if a localstorage saved random uuid specific to the user
 * should be used as part of the encryption key
 */
export function saveForUserContextEncrypted(key: string, payload: any, withKeyPart = false) {
    if (loginId && userContextId && secret) {
        saveToLocalStorage(
            buildUserContextKey(loginId, userContextId, key),
            encrypt(payload as any, getEncryptionKey(secret, withKeyPart ? getKeyPart(loginId) : loginId)),
        );
    }
}
