import {loginId, userContextId} from './local-storage-vars';
import {clearKey} from './clear-key';
import {buildUserContextKey} from './build-user-key';

/**
 * Clears the localstorage value with given key in the user's context
 *
 * @param key localstorage key to clear in user's context
 */
export function clearForUserContext(key: string) {
    if (loginId && userContextId) {
        clearKey(buildUserContextKey(loginId, userContextId, key));
    }
}
