/**
 * Clears the given key with the given prefix in localStorage
 *
 * @param key key to clear
 * @param prefix prefix to use `${prefix}.${key}`. defaults to 'famly'
 */
export function clearKey(key: string, prefix: string = 'famly') {
    try {
        window.localStorage.removeItem(`${prefix}.${key}`);
    } catch (exception) {}
}
