export type Breakpoint =
    | 'mobileLandscapeAndLarger'
    | 'tabletPortraitAndLarger'
    | 'tabletLandscapeAndLarger'
    | 'laptopAndLarger'
    | 'customAtLeastOneLargerThanLandscape';

// Inspired by https://ricostacruz.com/til/css-media-query-breakpoints;
export const mobileLandscapeAndLarger = '(min-width: 480px)';
export const tabletPortraitAndLarger = '(min-width: 768px)';
export const tabletLandscapeAndLarger = '(min-width: 992px)';
export const laptopAndLarger = '(min-width: 1200px)';

// Custom breakpoints
// Only used for navigation (tabbar + navbar). The mobile version should be shown on
// iPad landscape.
export const customAtLeastOneLargerThanLandscape = '(min-width: 1025px)';
