import {buildUserKey} from './build-user-key';
import {loginId} from './local-storage-vars';
import {saveToLocalStorage} from './save-to-local-storage';

/**
 * Saves the given payload to the user's localstorage.
 *
 * @param key key to use in localstorage
 * @param payload payload to save in localstorage at [key]
 */
export function saveForUserUnencrypted(key: string, payload: any) {
    if (loginId) {
        saveToLocalStorage(buildUserKey(loginId, key), payload);
    }
}
