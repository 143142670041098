import {hasValue} from '@famly/stat_ts-utils_has-value';

import {loginId, secret, userContextId} from './local-storage-vars';
import {getFromLocalStorage} from './get-from-local-storage';
import {buildUserContextKey} from './build-user-key';

/**
 * Checks if the given key exists in the user's context localstorage
 *
 * @param key key to check for existence
 * @returns true, if key exists in user's context localstorage, false otherwise
 */
export function existsForUserContext(key: string) {
    if (!loginId || !secret || !userContextId) {
        return false;
    }
    return hasValue(getFromLocalStorage(buildUserContextKey(loginId, userContextId, key)));
}
