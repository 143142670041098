import {decrypt, getEncryptionKey} from '@famly/stat_ts-utils_cryptography';

import {buildUserContextKey} from './build-user-key';
import {getFromLocalStorage} from './get-from-local-storage';
import {loginId, secret, userContextId} from './local-storage-vars';
import {getKeyPart} from './get-key-part';

/**
 * Decrypts the user context's localStorage value for the given key using crypto js and returns the result.
 *
 * @param key key to build the user context key with
 * @param withKeyPart boolean telling if a localstorage saved random uuid specific to the user
 * should be used as part of the decryption key
 * @returns the decrypted value for the given key nad keyPart combination
 */
export function getForUserContextEncrypted(key: string, withKeyPart?: boolean) {
    if (loginId && userContextId && secret) {
        const storedInfo = getFromLocalStorage(buildUserContextKey(loginId, userContextId, key));
        if (storedInfo) {
            return decrypt(storedInfo, getEncryptionKey(secret, withKeyPart ? getKeyPart(loginId) : loginId));
        }
    }
    return undefined;
}
