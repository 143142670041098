import {buildUserKey} from './build-user-key';
import {getFromLocalStorage} from './get-from-local-storage';
import {loginId} from './local-storage-vars';

/**
 * Get the unencrypted value in the user's localstorage for the given key
 *
 * @param key key to get from the localstorage
 * @returns value of the key in localstorage, null otherwise
 */
export function getForUserUnencrypted(key: string) {
    if (loginId) {
        return getFromLocalStorage(buildUserKey(loginId, key));
    }

    return undefined;
}
