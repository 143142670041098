import {buildUserKey} from './build-user-key';
import {loginId} from './local-storage-vars';
import {clearKey} from './clear-key';

/**
 * Clears the localstorage value with given key for the user
 *
 * @param key localstorage key to clear for the user
 */
export function clearForUser(key: string) {
    if (loginId) {
        clearKey(buildUserKey(loginId, key));
    }
}
