/**
 * Builds a local storage key for the given login id
 */
export function buildUserKey(loginId: string, key: string) {
    return `${loginId}.${key}`;
}

/**
 * Builds a local storage key for the given login id in hte specific user context
 */
export function buildUserContextKey(loginId: string, contextId: string, key: string) {
    return `${loginId}.${contextId}.${key}`;
}
