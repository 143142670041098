/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export type NonEmptyArray<T> = {
    0: T;
} & T[];

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const isNonEmpty = <T>(arr: T[] | NonEmptyArray<T>): arr is NonEmptyArray<T> => arr.length > 0;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const map = <T, P>(arr: NonEmptyArray<T>, fn: (x: T, i: number) => P): NonEmptyArray<P> =>
    arr.map(fn) as NonEmptyArray<P>;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const flatMap = <T, P>(arr: NonEmptyArray<T>, fn: (x: T) => NonEmptyArray<P>): NonEmptyArray<P> =>
    arr.flatMap(fn) as NonEmptyArray<P>;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const head = <T>([first]: NonEmptyArray<T>): T => first;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const tail = <T>([, ...tail]: NonEmptyArray<T>): T[] => tail;

type BooleanPredicate<T> = (x: T) => boolean;
type TypeGuardPredicate<T, U extends T> = (x: T) => x is U;
type BooleanOrTypeGuardPredicate<T, U extends T> = BooleanPredicate<T> | TypeGuardPredicate<T, U>;
/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export function every<T, U extends T>(arr: NonEmptyArray<T>, fn: TypeGuardPredicate<T, U>): arr is NonEmptyArray<U>;
/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export function every<T>(arr: NonEmptyArray<T>, fn: BooleanPredicate<T>): boolean;
/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export function every<T, U extends T>(arr: NonEmptyArray<T>, fn: BooleanOrTypeGuardPredicate<T, U>) {
    return arr.every(fn);
}

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const append = <T, P>(element: P, nonEmptyArray: NonEmptyArray<T>) =>
    [...nonEmptyArray, element] as unknown as NonEmptyArray<T | P>;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const insertAtIndex = <T>(element: T, idx: number, nonEmptyArray: NonEmptyArray<T>): NonEmptyArray<T> => {
    const newElements = [...nonEmptyArray.slice(0, idx), element, ...nonEmptyArray.slice(idx)];

    return newElements as NonEmptyArray<T>;
};

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const fromElement = <T>(element: T): NonEmptyArray<T> => [element];

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const last = <T>(arr: NonEmptyArray<T>): T => arr[arr.length - 1];

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const middle = <T>(arr: NonEmptyArray<T>): T => arr[Math.floor(arr.length / 2)];

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const sort = <T>(arr: NonEmptyArray<T>, fn?: Parameters<Array<T>['sort']>[0]): NonEmptyArray<T> =>
    [...arr].sort(fn) as NonEmptyArray<T>;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const nonEmptyArray = <T>(arr: NonEmptyArray<T>): NonEmptyArray<T> => arr;

/** @deprecated import from '@famly/stat_ts-utils_non-empty */
export const nonEmptyEvery = <T>(arr: T[], fn: Parameters<Array<T>['every']>[0]): boolean =>
    isNonEmpty(arr) && arr.every(fn);
