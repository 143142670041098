import {buildUserContextKey} from './build-user-key';
import {getFromLocalStorage} from './get-from-local-storage';
import {loginId, userContextId} from './local-storage-vars';

/**
 * Get the unencrypted value in the user context's localstorage for the given key
 *
 * @param key key to get from the localstorage
 * @returns value of the key in localstorage, null otherwise
 */
export function getForUserContextUnencrypted(key: string) {
    if (loginId && userContextId) {
        return getFromLocalStorage(buildUserContextKey(loginId, userContextId, key));
    }

    return undefined;
}
