import {buildUserContextKey} from './build-user-key';
import {loginId, userContextId} from './local-storage-vars';
import {saveToLocalStorage} from './save-to-local-storage';

/**
 * Saves the given payload to the user context's localstorage.
 *
 * @param key key to use in localstorage
 * @param payload payload to save in localstorage at [key]
 */
export function saveForUserContextUnencrypted(key: string, payload: any) {
    if (loginId && userContextId) {
        saveToLocalStorage(buildUserContextKey(loginId, userContextId, key), payload);
    }
}
