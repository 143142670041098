export let hasLocalStorageSupport: boolean | undefined;
try {
    hasLocalStorageSupport = 'localStorage' in window && window.localStorage !== null;
    const testKey = 'pascalprecht.translate.storageTest';
    window.localStorage.setItem(testKey, 'foo');
    window.localStorage.removeItem(testKey);
} catch (e) {
    hasLocalStorageSupport = false;
}

export let loginId: string | undefined;
export let userContextId: string | undefined;
export let secret: string | undefined;

/**
 * Set the login id which will be used for setting and getting user's localstorage values
 */
export const setLoginIdForLocalStorage = (id: string) => {
    loginId = id;
};

/**
 * Set the user's context id which will be used for setting and getting user context's localstorage values
 */
export const setUserContextIdForLocalStorage = (id: string) => {
    userContextId = id;
};

/**
 * Clear the login id which will be used for setting and getting user's localstorage values
 */
export const clearLoginIdForLocalStorage = () => {
    loginId = undefined;
};

/**
 * Clear the user's context id which will be used for setting and getting user context's localstorage values
 */
export const clearUserContextIdForLocalStorage = () => {
    userContextId = undefined;
};

/**
 * Set the secret for en-/decrypting values in localstorage
 */
export const setSecretForLocalStorage = (key: string) => {
    secret = key;
};

/**
 * Clear the secret for en-/decrypting values in localstorage
 */
export const clearSecretForLocalStorage = () => {
    secret = undefined;
};
