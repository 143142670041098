import {hasLocalStorageSupport} from './local-storage-vars';

/**
 * Saves the given data to the localstorage using the given key and prefix.
 *
 * @param key key to use in localstorage
 * @param data payload to save in localstorage at [key]
 * @param prefix prefix for the key to use. Defaults to 'famly'
 */
export function saveToLocalStorage(key: string, data: any, prefix = 'famly') {
    if (data !== null && data !== undefined && hasLocalStorageSupport) {
        try {
            window.localStorage.setItem(`${prefix}.${key}`, JSON.stringify(data));
        } catch (exception) {}
    }
}
