import {Device} from '@capacitor/device';
import {UAParser} from 'ua-parser-js';

import {breakpoints} from 'web-app/styleguide/utils';
import LocalStorageService from 'web-app/services/local-storage';
import SHARED_ENVIRONMENT_CONFIG from 'web-app/shared/environment';
import {customAtLeastOneLargerThanLandscape} from 'web-app/styleguide/breakpoints';
import {hasValue} from '@famly/stat_ts-utils_has-value';

export default class PlatformHelper {
    public static isDebug() {
        return Boolean(LocalStorageService.getFromLocalStorage('debugMode'));
    }

    public static isAndroid() {
        return SHARED_ENVIRONMENT_CONFIG.CURRENT_PLATFORM === 'android';
    }

    public static isIos() {
        return SHARED_ENVIRONMENT_CONFIG.CURRENT_PLATFORM === 'ios';
    }

    public static isMobileApp() {
        return this.isAndroid() || this.isIos();
    }

    public static isWeb() {
        return (
            SHARED_ENVIRONMENT_CONFIG.CURRENT_PLATFORM === 'html' ||
            SHARED_ENVIRONMENT_CONFIG.CURRENT_PLATFORM === 'docker'
        );
    }

    public static shouldUseNativeInputFields() {
        return this.isMobileApp();
    }

    // https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari
    public static isSafariMobile() {
        const ua = window.navigator.userAgent;
        const iOS = Boolean(ua.match(/iPad/i)) || Boolean(ua.match(/iPhone/i));
        const webkit = Boolean(ua.match(/WebKit/i));
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        return iOSSafari;
    }

    public static isSafariDesktop = () => {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    };

    public static isMobileSize() {
        return this.matchMediaMaxWidth(breakpoints.mobile);
    }

    public static isMobile() {
        return this.isMobileApp() || this.isMobileSize();
    }

    // For non-native mobile apps, in our case the sign in app: returns true if the device is a tablet (even if running in its browser)
    public static isRunningOnTablet() {
        const uaParser = new UAParser();
        return uaParser.getDevice().type === 'tablet';
    }

    // For non-native mobile apps, in our case the sign in app: returns true if the device is running on iOS
    public static isRunningOnIOS() {
        const uaParser = new UAParser();
        return uaParser.getOS().name === 'iOS';
    }

    /**
     * Returns true if the browser is webkit based _and_ is not the mobile app
     *
     * @returns boolean
     */
    public static isWebkitWeb() {
        const ua = window.navigator.userAgent;

        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#making_the_best_of_user_agent_sniffing
        const isWebkit =
            /\b(iPad|iPhone|iPod)\b/.test(ua) && /WebKit/.test(ua) && !/Edge/.test(ua) && !(window as any).MSStream;

        return !this.isMobileApp() && isWebkit;
    }

    public static isTabletSize() {
        return this.matchMediaMaxWidth(breakpoints.tablet);
    }

    public static isTablet() {
        return this.isMobileApp() || this.isTabletSize();
    }

    public static isLandscapeSize() {
        return this.matchMediaMaxWidth(breakpoints.landscape);
    }

    public static atLeastLandscape() {
        return this.matchMediaMinWidth(breakpoints.landscape);
    }

    public static matchMediaMinWidth(breakpoint: string) {
        return window.matchMedia(`(min-width: ${breakpoint})`).matches;
    }

    public static matchMediaMaxWidth(breakpoint: string) {
        return window.matchMedia(`(max-width: ${breakpoint})`).matches;
    }

    public static isPortraitSize() {
        return window.matchMedia(`(max-width: ${breakpoints.portrait})`).matches;
    }

    public static isDesktopSize() {
        return !(this.isMobileSize() || this.isTabletSize());
    }

    public static isTouch() {
        return 'ontouchstart' in window || this.isMobileApp();
    }

    /**
     * New breakpoints
     */

    public static customAtLeastOneLargerThanLandscape() {
        return window.matchMedia(customAtLeastOneLargerThanLandscape).matches;
    }

    public static hasPdfInIframeSupport() {
        return !this.isMobileApp();
    }

    public static viewportSize() {
        /**
         * From https://stackoverflow.com/a/8876069
         */
        return {
            width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) || null,
            height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) || null,
        };
    }

    public static store() {
        return SHARED_ENVIRONMENT_CONFIG.CURRENT_STORE && Store[SHARED_ENVIRONMENT_CONFIG.CURRENT_STORE as any]
            ? Store[SHARED_ENVIRONMENT_CONFIG.CURRENT_STORE]
            : this.fallbackStore();
    }

    public static allowPrint() {
        // Detection method from https://stackoverflow.com/a/29696509
        const ua = window.navigator.userAgent;
        const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
        const webkit = Boolean(ua.match(/WebKit/i));
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        return !iOSSafari && !this.isIos();
    }

    public static version() {
        return `${SHARED_ENVIRONMENT_CONFIG.APP.version}${
            this.isMobileApp() ? ` (${SHARED_ENVIRONMENT_CONFIG.APP.buildNumber})` : ''
        }`;
    }

    /**
     * Hints at whether we should dynamically change styling of the status bar
     * At the moment only relevant for iOS as we only "extend" the app contents to the status bar area on that platform.
     */
    public static hasDynamicStatusBar() {
        return this.isMobileApp();
    }

    private static fallbackStore() {
        if (this.isIos()) {
            return Store.APPSTORE;
        } else if (this.isAndroid()) {
            return Store.PLAYSTORE;
        } else {
            return undefined;
        }
    }

    /*
     * Returns the device major version if the user is in the mobile app and we can access it, null otherwise.
     */
    private static mobileAppDeviceMajorVersion(): Promise<number | null> {
        if (!this.isMobileApp()) {
            return Promise.resolve(null);
        }

        // This is how you get the device version in Capacitor:
        // https://capacitorjs.com/docs/apis/device#getinfo
        return Device.getInfo().then(info => {
            // They don't document what happens if they can't get the version, but I decided
            // to include a fallback just in case
            const deviceMajorVersion = info.osVersion.split('.')[0] || null;
            return hasValue(deviceMajorVersion) ? Number(deviceMajorVersion) : null;
        });
    }

    public static isIosVersionOrAbove(version: number) {
        return this.mobileAppDeviceMajorVersion().then(
            majorVersion => this.isIos() && hasValue(majorVersion) && majorVersion >= version,
        );
    }

    public static isIosVersionOrBelow(version: number) {
        return this.mobileAppDeviceMajorVersion().then(
            majorVersion => this.isIos() && hasValue(majorVersion) && majorVersion <= version,
        );
    }

    public static isIos13OrAbove() {
        return this.isIosVersionOrAbove(13);
    }

    public static isIos12OrBelow() {
        return this.isIosVersionOrBelow(12);
    }

    public static getManufacturer() {
        return Device.getInfo().then(info => info.manufacturer.toLowerCase());
    }
}

// eslint-disable-next-line no-restricted-syntax
export enum Store {
    APPSTORE = 'APPSTORE',
    PLAYSTORE = 'PLAYSTORE',
    AMAZONAPPSTORE = 'AMAZONAPPSTORE',
}
