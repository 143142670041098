import {getActiveTransaction, getCurrentHub, type SpanStatusType} from '@sentry/browser';
import {type Transaction} from '@sentry/types';
import {fromEvent, merge, type Observable, of, ReplaySubject, Subject} from 'rxjs';
import {filter, map, pairwise, share, startWith} from 'rxjs/operators';

import {SHOULD_ATTEMPT_TO_REESTABLISH_LAST_VISITED_ROUTE_QUERY_PARAM} from 'web-app/react/routes/constants';
import {findRouteTemplateFromUncleanUrl} from 'web-app/react/routes/helpers';
import {KeepAlive} from 'web-app/react/session/auto-logout/queries';
import {type InferObservable} from 'web-app/util/rxjs';
import {exhaustiveCheck, hasValue} from 'web-app/util/typescript';
import {namesOfGraphQLOperations} from 'web-app/util/graphql';
import {redactor} from 'web-app/util/logging';

import {withLatestSpanOrTimeout$} from './sentry-tracing-stubs';

/**
 * Inspired by the operation names used in the backend's tracing.
 * See permalink: https://github.com/famly/nuclearfamly/blob/43e2a2940fd8ecb1f86195b9ab225cb62b57adbd/famlylib/src/main/scala/co/famly/lib/tracing/Op.scala#L7
 */
export type OperationType = 'GQL' | 'Http';

export const TraceHeader = 'sentry-trace';

/**
 * Use this freely to attach any "event" to the active transaction if one is active.
 *
 * Transactions are handled automatically behind the scenes, so if you register a span
 * while there's no Transaction going on, nothing will happen.
 */
type TraceID = string;
type FinishSpan = (status: SpanStatusType) => void;
type RegisterSpanReturnType =
    | {
          trace: TraceID;
          finishSpan: FinishSpan;
          type: 'with-transaction';
      }
    | {
          type: 'no-transaction';
      }
    | {
          type: 'span-filtered';
      };
export function registerSpan(spanContext: SpanContext): RegisterSpanReturnType {
    if (shouldFilterSpan(spanContext)) {
        return {type: 'span-filtered'};
    }

    const transaction = getActiveTransaction();
    if (!transactionIsActive(transaction)) {
        // Early exit if no transaction is active
        return {type: 'no-transaction'};
    }

    // Create span in active Transaction
    const span = transaction.startChild({...spanContext, data: spanContext.data && redactor(spanContext.data)});

    // Return callback to finish Span
    return {
        type: 'with-transaction',
        /**
         * `toTraceParent` "returns a traceparent compatible header string", which means this is supposed to
         * be added to the `sentry-trace` header. It's structure is `traceid-spanid-sampled`
         * See https://develop.sentry.dev/sdk/performance/#header-sentry-trace
         */
        trace: span.toTraceparent(),
        finishSpan: (status: SpanStatusType) => {
            // Finish Span as soon as callback is invoked
            span.setStatus(status);
            span.finish();
        },
    };
}
type SpanContext = Omit<NonNullable<Parameters<Transaction['startChild']>[0]>, 'op' | 'description'> & {
    op: OperationType;
    description: string;
};

/**
 * There are some Spans we don't want to be registered on Transactions.
 * When this function returns true, no Span will be created.
 */
const blacklistedGqlOperations = namesOfGraphQLOperations(KeepAlive);
const blacklistedRESTEndpoints = ['client-events', 'v2/updates'];
const shouldFilterSpan = (spanContext: SpanContext) => {
    switch (spanContext.op) {
        case 'GQL':
            return blacklistedGqlOperations.includes(spanContext.description);
        case 'Http':
            return blacklistedRESTEndpoints.some(endpoint => spanContext.description.endsWith(endpoint));
        default:
            return false;
    }
};

/**
 * Will initialise and subscribe to tracing instrumentation when called.
 * Can be unsubscribed.
 */
export const startTracing = () => {
    // Set up listener for creating Transactions
    const startTransaction$ = startTransactionObservable$.pipe<Transaction>(
        // Compute URL template from absolute URL
        map((url: string) => {
            // Using utility function to find the matching route template.
            const pathFromUrl = findRouteTemplateFromUncleanUrl(url);

            return [pathFromUrl, url] as const;
        }),

        // Start Transaction
        map(([routeTemplate, originalURL]) =>
            getCurrentHub().startTransaction({
                name: routeTemplate || originalURL,
                op: 'Navigation',
            }),
        ),
        map((transaction: Transaction): Transaction => {
            /**
             * Here we monkey patch our custom instrumentation into the Transaction and
             * the Spans return by Transaction.startChild.
             *
             * The added code should keep the normal behavior of Transaction.startChild and Span.finish, but will also:
             * 1. Transaction.startChild will emit a cold observable subject to spanStream$
             * 2. Span.finish will emit a timestamp and Span.spanId on the aforementioned cold observable subject
             *
             * This is done to hook into Sentry's provided integration tools such as `Profiler` (withProfile) from `@sentry/react`
             */

            // Save reference to super
            const _startChild = transaction.startChild.bind(transaction);

            // Extend Transaction.startChild with our custom instrumentation
            transaction.startChild = function (context) {
                // Call super
                const span = _startChild(context);

                /**
                 * If the context represents an already finished event, such as `ui.long-task`
                 * we exit early and emit to spanStream$ ahead of time, since span.finish will never be called.
                 */
                if (context?.endTimestamp) {
                    spanStream$.next(of({finishedAt: context.endTimestamp, spanId: span.spanId}));
                    return span;
                }

                // Create cold observable subject to emit when Span finishes
                const finishSpan$ = new ReplaySubject<{finishedAt: number; spanId: string}>();

                // Emit span and subject that emits when Span should complete
                spanStream$.next(finishSpan$);

                // Save reference to super
                const _finish = span.finish.bind(span);

                // Extend Span.finish with our custom instrumentation
                span.finish = function (...args) {
                    // Call super
                    _finish(...args);

                    // Create timestamp. This will be used as the end-time of the Transaction if this is the final Span
                    finishSpan$.next({finishedAt: Date.now() / 1000, spanId: span.spanId});
                };

                return span;
            };
            return transaction;
        }),
        // Sharing this observable because it is subscribed to AND it is part of withLatestSpanOrTimeout$ which is also subscribed to
        share(),
    );

    /** Set up listener that starts when startTransaction$ emits,
     * and emits either on timeout or after spans have stopped registering
     */
    const finishTransaction$ = withLatestSpanOrTimeout$({
        // Value for timing out Span listeners
        idleTimeout: IDLE_TIMEOUT,
        // Observable that should emit when Spans are registered
        spanStream$,
        // Observable that should emit when a Transaction is started and we should start listening for Spans
        startTransaction$,
    });

    const subscription = merge(
        startTransaction$.pipe(map(withType('start'))),
        finishTransaction$.pipe(map(withType('finish'))),
    ).subscribe(result => {
        switch (result.type) {
            case 'start':
                startTransaction(result.value);
                return;
            case 'finish':
                finishTransaction(result.value);
                return;
            default:
                exhaustiveCheck(result);
        }
    });

    return subscription;
};

const startTransaction = (transaction: Transaction) => {
    const currentTransaction = getActiveTransaction();

    if (transactionIsActive(currentTransaction)) {
        // If a transaction is already active we finish it
        const status: SpanStatusType = 'aborted';
        currentTransaction.setStatus(status);
        currentTransaction.finish();
    }

    getCurrentHub().configureScope(scope => {
        scope.setSpan(transaction);
    });
};

const finishTransaction = (timestampOrTimeout: InferObservable<ReturnType<typeof withLatestSpanOrTimeout$>>) => {
    const currentTransaction = getActiveTransaction();
    // Early exit if there's no active Transaction
    if (!transactionIsActive(currentTransaction)) {
        return;
    }

    // If no spans were registered for the transaction we discard it
    if (timestampOrTimeout === 'NO_SPANS_REGISTERED') {
        const status: SpanStatusType = 'aborted';
        currentTransaction.setStatus(status);
        currentTransaction.finish(currentTransaction.startTimestamp);
        return;
    }

    // Finish the transaction using the timestamp of last finished Span
    const status: SpanStatusType = 'ok';
    currentTransaction.setStatus(status);
    currentTransaction.finish(timestampOrTimeout.finishedAt);
};

const withType =
    <T extends string>(type: T) =>
    <P>(value: P) => ({type, value});

// How long we wait until we determine the transaction is done if nothing happens
const IDLE_TIMEOUT = 500;

// Used to notify streams of new Spans being registered
// The SpanFinisher is an observable that is used to finish Spans via callbacks
export type SpanStreamValue = SpanFinisher;
const spanStream$ = new Subject<SpanStreamValue>();
type SpanFinisher = Observable<{finishedAt: number; spanId: string}>;

const urlHasChanged = (oldURL: string, newURL: string) => {
    const [oldURLBase] = oldURL.split('?');
    const [newURLBase] = newURL.split('?');
    return oldURLBase !== newURLBase;
};

const timeBetween = (t1: ReturnType<typeof Date.now>, t2: ReturnType<typeof Date.now>) => {
    return t2 - t1;
};

/**
 * Observable that emits when a navigation occurs. It will skip navigations that only affect query parameters
 * if they happen within a time threshold.
 */
const latestNavigationChange$ = fromEvent<HashChangeEvent>(window, 'hashchange').pipe(
    // We can't use HashChangeEvent.(oldURL|newURL) since it isn't supported by IE
    map(() => [Date.now(), window.location.href] as const),
    // Start with empty value so pairwise will emit on first hashchange event
    startWith([0 as number, 'nil' as string] as const),
    // Use pairwise operator to collect previous navigation events
    // This is used to discard emits if they are very close to eachother AND only query parameters has changed
    pairwise(),
    filter(([[oldTimestamp, oldURL], [newTimestamp, newURL]]) => {
        if (oldURL === 'nil') {
            // Let values pass for first hashchange event
            return true;
        }

        /**
         * Let values pass if URL has changed
         * Otherwise, if last navigation was within some threshold, then discard the new value
         */
        return urlHasChanged(oldURL, newURL) || timeBetween(newTimestamp, oldTimestamp) > IDLE_TIMEOUT;
    }),
    // Unwrap the pairwise operator
    map(([, [, url]]) => url),
);

// When this observable emits, the entire Transaction flow is started.
// In the future we can extend this to include other things as well by using the `merge` combinator.
const startTransactionObservable$ = latestNavigationChange$.pipe(
    filter(url => {
        const isRedirecting = url.includes(SHOULD_ATTEMPT_TO_REESTABLISH_LAST_VISITED_ROUTE_QUERY_PARAM);

        return !isRedirecting;
    }),
);

// Utility and typeguard used to exit early if spans are registered while there's no Transaction.
const transactionIsActive = (transaction: Transaction | undefined): transaction is Transaction =>
    hasValue(transaction) && !hasValue(transaction.endTimestamp);
