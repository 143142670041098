import {
    buildUserContextKey,
    buildUserKey,
    getAllKeys,
    saveToLocalStorage,
    getFromLocalStorage,
    clearKey,
    saveForUserEncrypted,
    saveForUserContextEncrypted,
    saveForUserUnencrypted,
    saveForUserContextUnencrypted,
    getForUserUnencrypted,
    getForUserContextUnencrypted,
    existsForUser,
    existsForUserContext,
    getForUserContextEncrypted,
    getForUserEncrypted,
    clearForUser,
    clearForUserContext,
} from '@famly/stat_ts-utils_local-storage';

const LocalStorage = {
    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    buildUserKey(loginId: string, key: string) {
        return buildUserKey(loginId, key);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    buildUserContextKey(loginId: string, contextId: string, key: string) {
        return buildUserContextKey(loginId, contextId, key);
    },

    // --- General Local Storage Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    getAllKeys() {
        return getAllKeys();
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    saveToLocalStorage(key: string, data, prefix = 'famly') {
        return saveToLocalStorage(key, data, prefix);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    getFromLocalStorage(key: string, prefix = 'famly') {
        return getFromLocalStorage(key, prefix);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    clearKey(key: string, prefix = 'famly') {
        return clearKey(key, prefix);
    },

    // --- Save Encrypted Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    saveForUserEncrypted(key: string, payload, withKeyPart = false) {
        return saveForUserEncrypted(key, payload, withKeyPart);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    saveForUserContextEncrypted(key: string, payload, withKeyPart = false) {
        return saveForUserContextEncrypted(key, payload, withKeyPart);
    },

    // --- Save Unencrypted Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    saveForUserUnencrypted(key: string, payload) {
        return saveForUserUnencrypted(key, payload);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    saveForUserContextUnencrypted(key, payload) {
        return saveForUserContextUnencrypted(key, payload);
    },

    // --- Get Unencrypted Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    getForUserUnencrypted(key) {
        return getForUserUnencrypted(key);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    getForUserContextUnencrypted(key) {
        return getForUserContextUnencrypted(key);
    },

    // --- Exists Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    existsForUser(key) {
        return existsForUser(key);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    existsForUserContext(key: string) {
        return existsForUserContext(key);
    },

    // --- Get Encrypted Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    getForUserEncrypted(key: string, withKeyPart?: boolean) {
        return getForUserEncrypted(key, withKeyPart);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    getForUserContextEncrypted(key: string, withKeyPart?: boolean) {
        return getForUserContextEncrypted(key, withKeyPart);
    },

    // --- Clear Functions ---

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    clearForUser(key: string) {
        return clearForUser(key);
    },

    /** @deprecated import from `@famly/stat_ts-utils_local-storage` */
    clearForUserContext(key: string) {
        return clearForUserContext(key);
    },

    // --- Deprecated Functions ---

    /** @deprecated use getForUserEncrypted or getForUserUnencrypted */
    getForUser(key: string, withKeyPart = false) {
        return this.getForUserEncrypted(key, withKeyPart);
    },

    /** @deprecated use saveForUserEncrypted or saveForUserUnencrypted */
    saveForUser(key: string, payload, withKeyPart = false) {
        this.saveForUserEncrypted(key, payload, withKeyPart);
    },
};

export default LocalStorage;
