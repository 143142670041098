import {hasValue} from '@famly/stat_ts-utils_has-value';

import {loginId, secret} from './local-storage-vars';
import {getFromLocalStorage} from './get-from-local-storage';
import {buildUserKey} from './build-user-key';

/**
 * Checks if the given key exists in the user's localstorage
 *
 * @param key key to check for existence
 * @returns true, if key exists in user's localstorage, false otherwise
 */
export function existsForUser(key: string) {
    if (!loginId || !secret) {
        return false;
    }
    return hasValue(getFromLocalStorage(buildUserKey(loginId, key)));
}
