import {hasLocalStorageSupport} from './local-storage-vars';

/**
 * @returns all localstorage keys
 */
export function getAllKeys() {
    if (!hasLocalStorageSupport) {
        return [];
    }

    return Object.keys(window.localStorage);
}
