import {hasLocalStorageSupport} from './local-storage-vars';

/**
 * Returns the value of the key created by concatenating the prefix with the given key.
 *
 * @param key key of the localstorage value to get
 * @param prefix prefix of the value. Defaults to 'famly'
 * @returns returns the localstorage value if it exists, null otherwise
 */
export function getFromLocalStorage(key: string, prefix: string = 'famly') {
    if (!hasLocalStorageSupport) {
        return null;
    }

    return JSON.parse(window.localStorage.getItem(`${prefix}.${key}`) as string);
}
