import {generateUUID} from '@famly/stat_ts-utils_generate-uuid';
import {KEYPART_KEY} from '@famly/stat_ts-utils_famlytics';

import {getFromLocalStorage} from './get-from-local-storage';
import {saveToLocalStorage} from './save-to-local-storage';

/**
 * Gets an existing or creates a new, randomly generated key for the given loginId.
 *
 * This key can then be used for encryption purposes
 *
 * @param loginId id of the user for who we want to create a new key
 * @returns the user's existing key, if one exists or a new one otherwise. Saves any
 * newly generated ids to the localstorage
 */
export const getKeyPart = (loginId: string) => {
    const allKeyParts = (getFromLocalStorage(KEYPART_KEY) as {[x: string]: string}) || {};
    let storedKeyPart = allKeyParts[loginId];

    if (!storedKeyPart) {
        storedKeyPart = generateUUID();
        allKeyParts[loginId] = storedKeyPart;
        saveToLocalStorage(KEYPART_KEY, allKeyParts);
    }

    return storedKeyPart;
};
